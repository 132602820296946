@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

// == Minimage Mistakes Theme Modifications ===================================

.sidebar {
    opacity: 1.0; // remove opacity
    width: calc(230px - 1em); // decrease default width
}

.author__avatar img {
    max-width: 150px; // increse avatar size
}

.page {
    padding-right: 150px; // reduce padding for page content
}

html {
    font-size: 18px; // decrease default font-size
}

// == Publictions Styles ======================================================

.publication-info {
    margin-top: 3px;
    margin-bottom: 5px;
}

.publication-info a {
    margin-right: 10px;
    margin-top: 10px;
}

// == Styles for Courses ======================================================

.term-info {
    font-weight: normal;
}

.term-label {
    font-weight: normal;
    padding-right: 2em;
}

.course-info:not(:first-child) {
    margin-top: 1em;
}

.term-label {
    vertical-align: top;
}

.course-comment {
    font-style: italic;
}

.course-table {
    font-size: 1em;
}